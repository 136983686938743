import React, { useState } from 'react';
import Image, { ImageProps } from 'next/image';

interface WsStaticImageProps extends ImageProps {
  alt: string;
}

const WsStaticImage: React.FC<WsStaticImageProps> = ({
  src,
  alt,
  ...props
}) => {
  const s3path = process.env.NEXT_PUBLIC_IMAGE_S3_BUCKET_BASE_URL;
  const [fullPath, setFullPath] = useState(
    typeof src === 'string' && src.endsWith('.svg') ? src : `${s3path}${src}`
  );
  const handleError = () => {
    console.log(`Failed to load image: ${fullPath}`);
    setFullPath('/images/error.jpg');
  };
  return <Image src={fullPath} alt={alt} onError={handleError} {...props} />;
};

export default WsStaticImage;
// export default memo(WsStaticImage);
