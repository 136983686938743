import { ReduxWrapper } from '@/components/common/ReduxWrapper';
import useIsMobile from '@/hooks/useMediaQuery';
import store from '@/store';
import dynamic from 'next/dynamic';
import React, { ReactNode, useState } from 'react';
import { Provider } from 'react-redux';
const HeaderMobile = dynamic(() => import('./components/mobile/HeaderMobile'));
const FooterMobile = dynamic(
  () => import('./components/mobile/footer-mobile'),
  { ssr: false }
);
const ScrollToTopButton = dynamic(
  () => import('@/components/common/ScrollTop'),
  { ssr: false }
);
const RequestCall = dynamic(() => import('@/components/common/request-call'), {
  ssr: false,
});
const BuyOnPhone = dynamic(() => import('@/components/common/buy-on-phone'), {
  ssr: false,
});
const WhatsappCall = dynamic(() => import('@/components/home/whatsapp-call'), {
  ssr: false,
});

// import Zendesk from '@/components/common/Zendesk';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useRouter } from 'next/router';
import { ZendeskProvider } from 'react-use-zendesk';
const Header = dynamic(() => import('./components/Header'));
const Footer = dynamic(() => import('./components/Footer'), { ssr: false });
const DiscountStrip = dynamic(() => import('./components/DiscountStrip'));
const FooterTitleMobile = dynamic(
  () => import('./components/mobile/footer-title-link-mobile'),
  {
    ssr: false,
  }
);
const LoginModal = dynamic(() => import('./components/login-modal'), {
  ssr: false,
});

type Props = {
  children: ReactNode & {
    props?: any;
  };
  data?: any;
  isMobile?: boolean;
};

const Layout = ({ children, data = {} }: Props) => {
  const { infoData = {}, error = null } = data || {};

  const clientMobile = useIsMobile();
  // const isMobile = useMediaQuery('only screen and (max-width: 768px)');
  const isMobile = data?.isMobile ? data?.isMobile : clientMobile;

  const router = useRouter();
  const isTrackOrderPage = router.pathname === '/track-order-by-email';
  // const isMobile = false;

  const [showZendesk, setShowZendesk] = useState(false);

  const handleMouseMove = () => {
    if (!showZendesk) {
      setShowZendesk(true);
    }
  };

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_ID as string}>
      <Provider store={store}>
        <ReduxWrapper>
          <div className="common-wrapper">
            <LoginModal isMobile={isMobile} />
            {isMobile ? (
              <>
                <HeaderMobile />
                <WhatsappCall />
                {showZendesk && (
                  <>
                    <RequestCall />
                  </>
                )}
              </>
            ) : (
              <>
                <DiscountStrip />
                <Header />
                {showZendesk && (
                  <>
                    <ScrollToTopButton />
                    <RequestCall />
                    <BuyOnPhone />
                  </>
                )}
              </>
            )}

            <main
              className={isTrackOrderPage ? 'track-order-page' : ''}
              onMouseMove={handleMouseMove}
            >
              {children}
            </main>

            {showZendesk && (
              <ZendeskProvider
                apiKey={process.env.NEXT_PUBLIC_ZENDESK_API_KEY || ''}
              >
                {isMobile ? (
                  <>
                    <FooterMobile />
                    <FooterTitleMobile
                      informationData={infoData?.data}
                      error={error}
                    />
                  </>
                ) : (
                  <Footer />
                )}
              </ZendeskProvider>
            )}
          </div>
        </ReduxWrapper>
      </Provider>
    </GoogleOAuthProvider>
  );
};

export default Layout;
